
import useProgressRouter from '@hooks/useProgressRouter'
import { agencyDetail, agentDetail, estimateMapSuburb, houseDetail } from '@router'
import { concat, filter, find, findIndex, get, isEqual, map, replace, size, take, takeRight } from 'lodash-es'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useBoolean, useList } from 'react-use'
import { isSuburbSearchType } from './filter/config/content'

export const HomeSearchContext = createContext()
export default function useHomeSearchContext() {
  return useContext(HomeSearchContext)
}

export const ITEM_TYPE = {
  suburb: 'suburb',
  office: 'office',
  agent: 'agent',
  street: 'street',
  property: 'property',
  village: 'village',
}

export function useHomeSearchState({
  oneSuburb = false,
  recentSuburb = true,
  recentUseSuburb = true,
  searchType,
  defaultSuburbs = [],
  isMapArea = false,
  defaultSurrounding = false
} = {}) {

  // router
  const { pushRouter } = useProgressRouter()
  // 搜索关键词
  const [keyword, setKeyword] = useState('')
  // 已选择的suburbs
  const [suburbs, { set: setSuburbs, push: pushSuburb, removeAt: removeAtSuburb, clear: clearSuburbs }] = useList(defaultSuburbs)
  // 是否已设置过本地suburbs
  const [isPutLocal, togglePutLocal] = useBoolean(false)
  // 从本地获取上次搜索的suburbs
  useEffect(() => {
    if (size(suburbs) > 0 || isPutLocal) return
    togglePutLocal(true)
    if (recentSuburb) {
      const list = getPrevSearchSuburbs(recentUseSuburb)
      setSuburbs(oneSuburb ? take(list, 1) : list)
    }
  }, [suburbs, setSuburbs, recentSuburb, oneSuburb, isPutLocal, recentUseSuburb, togglePutLocal])
  // 当前输入框内的suburb是否和url上的suburb一致
  const isSameSuburb = useMemo(() => {
    const curIds = map(suburbs, 'id')
    const defalutIds = map(defaultSuburbs, 'id')
    return isEqual(curIds, defalutIds)
  }, [suburbs, defaultSuburbs])

  // 是否有选择的suburbs
  const hasChoice = useMemo(() => !!size(suburbs), [suburbs])
  // 是否显示弹窗
  const [displayPopup, togglePopup] = useBoolean(false)
  // 是否显示周边城区
  const [surrounding, toggleSurrounding] = useBoolean(defaultSurrounding)
  // 当前正在显示的数据列表
  const [dataList, setDataList] = useState([])
  // 当前选中的索引
  const [selectIdx, setSelectIdx] = useState(-1)
  // 当前选中的
  const currentSelect = useMemo(() => {
    if (!size(dataList) || selectIdx < 0) return
    return dataList[selectIdx]
  }, [selectIdx, dataList])
  // 如果显示数据发生变化，重置选中的索引
  useEffect(() => setSelectIdx(0), [dataList, setSelectIdx])

  // 是否显示选择地区
  const [displaySelectLocation, toggleSelectLocation] = useBoolean(false)
  // 是否显示filter
  const [displayFilter, toggleFilter] = useBoolean(false)
  // 是否suburb搜索
  const isSearchSuburb = useMemo(() => isSuburbSearchType(searchType), [searchType])

  // 添加suburb去重
  const addSuburb = useCallback(data => {
    if (oneSuburb || isSearchSuburb) {
      setSuburbs([data])
    } else {
      if (find(suburbs, item => item.id === data.id)) return
      pushSuburb(data)
    }
  }, [pushSuburb, suburbs, setSuburbs, oneSuburb, isSearchSuburb])
  // 删除suburb
  const removeSuburb = useCallback(data => {
    let idx = findIndex(suburbs, data)
    if (idx !== -1) removeAtSuburb(idx)
  }, [removeAtSuburb, suburbs])
  // 删除最后一个suburb
  const removeSuburbLast = useCallback(() => {
    removeAtSuburb(size(suburbs) - 1)
  }, [removeAtSuburb, suburbs])
  // 修改关键词
  const changeKeyword = useCallback(value => {
    const val = get(value, 'target.value', value)
    localStorage.setItem('or-home-search-keyword', val)
    setKeyword(val)
  }, [setKeyword])
  const [showAllSuburbs, toggleShowAllSuburbs] = useBoolean(false)
  const showAllSuburbsHandle = useCallback((event) => {
    event.stopPropagation()
    toggleShowAllSuburbs(true)
  }, [toggleShowAllSuburbs])
  // 显示popup
  const showPopup = useCallback(() => {
    togglePopup(true)
    toggleShowAllSuburbs(true)
  }, [togglePopup, toggleShowAllSuburbs])
  // 隐藏popup
  const hidePopup = useCallback(() => {
    togglePopup(false)
    toggleShowAllSuburbs(false)
  }, [togglePopup, toggleShowAllSuburbs])
  // 选中上一个
  const selectPrev = useCallback(() => {
    if (!size(dataList)) return
    const idx = selectIdx - 1
    if (idx < 0) {
      setSelectIdx(size(dataList) - 1)
    } else {
      setSelectIdx(idx)
    }
  }, [selectIdx, setSelectIdx, dataList])
  // 选中下一个
  const selectNext = useCallback(() => {
    if (!size(dataList)) return
    const idx = selectIdx + 1
    if (idx === size(dataList)) {
      setSelectIdx(0)
    } else {
      setSelectIdx(idx)
    }
  }, [selectIdx, setSelectIdx, dataList])
  // 选择单个
  const selectItem = useCallback((data, hidePop = true) => {
    const d = data || currentSelect
    if (!d) return
    switch (d.dataType) {
      case ITEM_TYPE.suburb:
        addSuburb(d)
        break
      case ITEM_TYPE.agent:
        pushRouter(agentDetail(d))
        break
      case ITEM_TYPE.office:
        pushRouter(agencyDetail(d))
        break
      case ITEM_TYPE.property:
      case ITEM_TYPE.listing:
        pushRouter(houseDetail(d))
        break
      case ITEM_TYPE.street:
        pushRouter(estimateMapSuburb(d))
        break
      default:
        console.log('selectItem', d.dataType)
        break
    }
    hidePop && hidePopup()
    changeKeyword('')
  }, [hidePopup, currentSelect, changeKeyword, addSuburb, pushRouter])

  // 显示选择地区
  const showSelectLocation = useCallback(() => {
    toggleSelectLocation(true)
    hidePopup()
  }, [toggleSelectLocation, hidePopup])
  // 隐藏选择地区
  const hideSelectLocation = useCallback(() => {
    toggleSelectLocation(false)
  }, [toggleSelectLocation])

  // 显示filter
  const showFilter = useCallback(() => {
    toggleFilter(true)
    hidePopup()
  }, [toggleFilter, hidePopup])
  // 隐藏filter
  const hideFilter = useCallback(() => {
    toggleFilter(false)
  }, [toggleFilter])

  return {
    keyword,
    suburbs,
    hasChoice,
    displayPopup,
    dataList,
    selectIdx,
    currentSelect,
    surrounding,
    displaySelectLocation,
    displayFilter,
    oneSuburb,
    isSearchSuburb,
    isMapArea,
    showAllSuburbs,
    isSameSuburb,
    showAllSuburbsHandle,
    changeKeyword,
    setSuburbs,
    addSuburb,
    removeSuburb,
    removeSuburbLast,
    clearSuburbs,
    togglePopup,
    showPopup,
    hidePopup,
    setDataList,
    selectPrev,
    selectNext,
    setSelectIdx,
    selectItem,
    toggleSurrounding,
    showSelectLocation,
    hideSelectLocation,
    showFilter,
    hideFilter
  }
}

export function pertainType(arr, type) {
  return map(arr, item => {
    item.dataType = type
    return item
  })
}

// 获取建议的suburbs
export function getSuggestedSuburbs(showSuburb = true) {
  try {
    let list = JSON.parse(localStorage.getItem('or-suggested-suburbs'))
    if (list) {
      list = map(list, item => {
        item.label = replace(get(item, 'label'), /<\/?[^>]+>/g, '')
        return item
      }).filter(item => item.level !== 'suburb' || showSuburb)
    }
    return list || []
  } catch (error) {
    return []
  }
}
// 缓存建议suburbs
export function cacheSuggestedSuburbs(data) {
  const list = getSuggestedSuburbs()
  const ids = map(list, item => item.id)
  const arr = filter(data, item => !ids.includes(item.id))
  localStorage.setItem('or-suggested-suburbs', JSON.stringify(takeRight(concat(list, arr), 5)))
  cachePrevSearchSuburbs(data)
}
// 获取上一次搜索的suburbs
export function getPrevSearchSuburbs(useSuburb = true) {
  try {
    const res = JSON.parse(localStorage.getItem('or-prev-search-suburbs')) || []
    return res.filter(item => item.level !== 'suburb' || useSuburb)
  } catch (error) {
    return []
  }
}
// 缓存上一次搜索的suburbs
export function cachePrevSearchSuburbs(data) {
  localStorage.setItem('or-prev-search-suburbs', JSON.stringify(data))
}