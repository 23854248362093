'use client'

import { RESIDENTIAL_TABS } from '@config'
import IconFilter from '@icon/home/filter.svg'
import IconMoreFilter from '@icon/house/more-filter.svg'
import IconSearch from '@icon/profile/search.svg'
import { global } from '@store/index'
import classNames from 'classnames'
import { get, isEqual, isFunction, map, size } from 'lodash-es'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useBoolean, useClickAway, useEvent } from 'react-use'
import { useRecoilValue } from 'recoil'
import useHomeSearchContext from '../context'

export default function HomeSearchInput({
  placeholder = 'Type an address, suburb, or city',
  mobilePlaceholder = 'Type address, suburb, or city',
  filterButton = false,
  searchIcon = false,
  prefixIcon = null,
  children,
  onSearch,
  onFilter,
  onFocus,
  showSearchButton = true,
  autoFocus = false
}) {

  const { isMobile } = useRecoilValue(global)
  const [placeholderText, setPlaceholderText] = useState(placeholder)
  useEffect(() => {
    setPlaceholderText(isMobile ? mobilePlaceholder : placeholder)
  }, [isMobile, setPlaceholderText, placeholder, mobilePlaceholder])

  const inputWrapRef = useRef()
  const inputRef = useRef(null)
  const {
    keyword,
    changeKeyword,
    displayPopup,
    showPopup,
    hidePopup,
    selectPrev,
    selectNext,
    selectItem,
    suburbs,
    hasChoice,
    removeSuburbLast,
    surrounding,
    isMapArea,
    currentSelect,
    clearSuburbs,
  } = useHomeSearchContext()
  useClickAway(inputWrapRef, hidePopup)

  // popup处于现实状态，suburbs才全部显示，否则只显示三个,mobile显示一个
  const viewSuburbs = useMemo(() => displayPopup ? suburbs : suburbs.slice(0, 1), [displayPopup, suburbs])

  // 选中input
  const inputAreaClick = useCallback(() => {
    if (inputRef.current) inputRef.current.focus()
    isFunction(onFocus) && onFocus()
    showPopup()
  }, [inputRef, showPopup, onFocus])

  const searchHandle = useCallback(() => {
    isFunction(onSearch) && onSearch({ suburbs, surrounding })
  }, [onSearch, suburbs, surrounding])
  const filterHandle = useCallback(() => {
    isFunction(onFilter) && onFilter({ suburbs, surrounding })
  }, [onFilter, suburbs, surrounding])
  useEffect(() => {
    if (showSearchButton) return
    isFunction(onSearch) && onSearch({ suburbs, surrounding })
  }, [suburbs, surrounding, onSearch, showSearchButton])

  // 键盘上下选中
  const keydownHandle = useCallback(event => {
    const { key } = event
    if (key === 'ArrowDown') {
      selectNext()
    } else if (key === 'ArrowUp') {
      event.preventDefault()
      selectPrev()
    } else if (key === 'Backspace') {
      if (!keyword) removeSuburbLast()
    } else if (key === 'Enter') {
      // 如果没有keywords并且popup没有显示
      // 或者当前没有选择某一行
      // 执行搜索事件
      if ((!keyword && !displayPopup) || !currentSelect) {
        searchHandle()
      } else {
        selectItem()
      }
      event.preventDefault()
    }
  }, [selectPrev, selectNext, selectItem, removeSuburbLast, displayPopup, currentSelect, keyword, searchHandle])
  useEvent('keydown', keydownHandle, inputRef.current)

  return (
    <div className='relative' ref={inputWrapRef}>
      <div className='flex'>
        <div className={classNames(
          'border flex-1 min-w-0 flex items-center bg-white',
          { 'border-r-0': showSearchButton },
          showSearchButton && (displayPopup ? 'rounded-tl-sm' : 'rounded-l-sm'),
          (!showSearchButton) && (displayPopup ? 'rounded-t-sm' : 'rounded-sm')
        )}>
          {
            searchIcon && (
              <IconSearch className='ml-10 fill-neutral' width={22} height={22}></IconSearch>
            )
          }
          {prefixIcon}
          <div className='flex-1 flex flex-wrap py-6 cursor-text px-6 min-w-0 sm:px-10 sm:py-10' onClick={inputAreaClick}>
            {
              isMapArea
                ? <SuburbItem more>Map area</SuburbItem>
                : map(viewSuburbs, item => (
                  <SuburbItem data={item} key={item.id}></SuburbItem>
                ))
            }
            {
              size(suburbs) > size(viewSuburbs) && (
                <SuburbItem more>+{size(suburbs) - 1}</SuburbItem>
              )
            }
            {
              displayPopup && size(suburbs) > 10 && (
                <SuburbItem more onClick={clearSuburbs}>Clear</SuburbItem>
              )
            }
            <input
              type='text'
              className='m-4 flex-1 h-26 text-base min-w-[18px] text-black'
              ref={inputRef}
              placeholder={hasChoice ? undefined : (placeholderText)}
              value={keyword}
              onChange={changeKeyword}
              autoFocus={autoFocus}
              aria-label='Type an address, suburb, or city'
            />
          </div>
          {
            filterButton && (
              <button type='button' className='bg-neutral flex items-center rounded-sm !h-32 text-sm px-8 mr-6 sm:px-12 sm:mr-10 sm:w-134 sm:button-primary-out' onClick={filterHandle}>
                <span className='hidden sm:inline-block'>Search filters</span>
                <IconMoreFilter width={18} height={18} className='fill-primary sm:hidden'></IconMoreFilter>
              </button>
            )
          }
        </div>
        {
          showSearchButton && (
            <button type='button' className={classNames('button-orange w-56 h-auto rounded-l-none', { 'rounded-br-none': displayPopup })} onClick={searchHandle} aria-label='search'>
              <i className='icon icon-search !text-xl'></i>
            </button>
          )
        }
      </div>
      {children}
    </div>
  )
}

function SuburbItem({
  data,
  children,
  more = false,
  onClick
}) {

  const label = useMemo(() => {
    if (more) return
    let res = data.label || data.pureLabel
    if (res) res = res.replace(/<\/?mark>/g, '')
    return res
  }, [data, more])
  const { removeSuburb } = useHomeSearchContext()
  const clickHandle = useCallback(() => {
    removeSuburb(data)
  }, [data, removeSuburb])
  return (
    <div className={classNames(
      'm-4 text-base leading-18 pl-8 py-2 text-black bg-neutral-1 rounded-2xl inline-flex items-center hover:bg-primary hover:text-white sm:pl-12 min-w-0',
      { 'pr-12': more },
      { 'cursor-pointer': onClick }
    )} onClick={onClick}>
      <span className='flex-1 min-w-0 whitespace-nowrap text-ellipsis overflow-hidden'>{label}</span>
      {children}
      {!more && <i className='icon icon-close !text-xs cursor-pointer inline-flex items-center justify-center w-18 h-18 mx-4 rounded-half bg-[#D9D9D9]' onClick={clickHandle}></i>}
    </div>
  )
}

export function HomeSearchInputNew({
  placeholder = 'Type an address, suburb, or city',
  mobilePlaceholder = 'Type address, suburb, or city',
  filterButton = false,
  searchIcon = false,
  filterButtonClassName,
  children,
  onSearch,
  onFilter,
  onFocus,
  showSearchButton = true,
  onInputClick,
  autoFocus = false,
  mobileDisableInput = false,
  showTypes = false
}) {

  const { isMobile } = useRecoilValue(global)
  const [inputReadOnly, setInputReadOnly] = useState(false)
  useEffect(() => setInputReadOnly(isMobile && mobileDisableInput), [setInputReadOnly, isMobile, mobileDisableInput])
  const [placeholderText, setPlaceholderText] = useState(placeholder)
  useEffect(() => {
    setPlaceholderText(isMobile ? mobilePlaceholder : placeholder)
  }, [isMobile, setPlaceholderText, placeholder, mobilePlaceholder])

  const inputWrapRef = useRef()
  const inputRef = useRef(null)
  const {
    keyword,
    changeKeyword,
    displayPopup,
    showPopup,
    hidePopup,
    selectPrev,
    selectNext,
    selectItem,
    suburbs,
    hasChoice,
    removeSuburbLast,
    surrounding,
    isMapArea,
    currentSelect,
    clearSuburbs,
    type,
    setType,
    togglePopup,
    showAllSuburbs,
    showAllSuburbsHandle,
  } = useHomeSearchContext()
  useClickAway(inputWrapRef, hidePopup)

  // popup处于现实状态，suburbs才全部显示，否则只显示三个,mobile显示一个
  const viewSuburbs = useMemo(() => displayPopup || showAllSuburbs ? suburbs : suburbs.slice(0, 1), [displayPopup, suburbs, showAllSuburbs])

  // 选中input
  const inputAreaClick = useCallback(() => {
    if (inputReadOnly) {
      isFunction(onInputClick) && onInputClick()
      return
    }
    if (inputRef.current) inputRef.current.focus()
    isFunction(onFocus) && onFocus()
    showPopup()
  }, [inputRef, showPopup, onFocus, inputReadOnly, onInputClick])

  const searchHandle = useCallback(() => {
    isFunction(onSearch) && onSearch({ suburbs, surrounding })
  }, [onSearch, suburbs, surrounding])
  const filterHandle = useCallback(() => {
    isFunction(onFilter) && onFilter({ suburbs, surrounding })
  }, [onFilter, suburbs, surrounding])
  useEffect(() => {
    if (showSearchButton) return
    isFunction(onSearch) && onSearch({ suburbs, surrounding })
  }, [suburbs, surrounding, onSearch, showSearchButton])

  // 键盘上下选中
  const keydownHandle = useCallback(event => {
    const { key } = event
    if (key === 'ArrowDown') {
      selectNext()
    } else if (key === 'ArrowUp') {
      event.preventDefault()
      selectPrev()
    } else if (key === 'Backspace') {
      if (!keyword) removeSuburbLast()
    } else if (key === 'Enter') {
      // 如果没有keywords并且popup没有显示
      // 或者当前没有选择某一行
      // 执行搜索事件
      if ((!keyword && !displayPopup) || (!currentSelect && !displayPopup)) {
        searchHandle()
      }
      else {
        selectItem()
      }
      event.preventDefault()
    }
  }, [
    selectPrev,
    selectNext,
    selectItem,
    removeSuburbLast,
    displayPopup,
    currentSelect,
    keyword,
    searchHandle,
  ])
  useEvent('keydown', keydownHandle, inputRef.current)

  // 选择type
  const [typeVisible, toggleTypeVisible] = useBoolean(false)
  const typeRef = useRef(null)
  useClickAway(typeRef, () => {
    toggleTypeVisible(false)
  })

  return (
    <div className='relative' ref={inputWrapRef}>
      <div className='flex flex-col relative sm:flex-row'>
        <div className='flex-1 flex min-w-0 min-h-[66px]'>
          {
            showTypes && (
              <div className='relative' ref={typeRef}>
                <div
                  className={classNames(
                    'bg-white sm:bg-neutral-2 pl-10 sm:pl-0 cursor-pointer h-full flex items-center sm:w-134 justify-center gap-x-4 border !border-r-0 text-primary sm:text-font',
                    displayPopup ? 'rounded-tl-sm' : 'rounded-tl-sm sm:rounded-l-sm'
                  )}
                  onClick={() => { toggleTypeVisible(); togglePopup(false) }}
                >
                  <span className='text-base sm:text-lg sm:font-medium'>{get(type, 'label', 'Buy')}</span>
                  <i className='icon icon-arrow-down !text-sm'></i>
                </div>
                {
                  typeVisible && (
                    <TypeOption typesConfig={RESIDENTIAL_TABS} type={type} setType={setType} toggleTypeVisible={toggleTypeVisible} />
                  )
                }
              </div>
            )
          }
          <div className={classNames(
            'border flex-1 min-w-0 flex items-center bg-white rounded-t-sm sm:rounded-sm',
            { 'border-r-0 ': showSearchButton },
            { 'border-l-0': showTypes },
            { 'sm:!rounded-r-none': showSearchButton },
            { '!rounded-b-none': displayPopup },
            { '!rounded-l-none': showTypes },
          )}>
            {
              searchIcon && (
                <IconSearch className='ml-10 fill-neutral' width={22} height={22}></IconSearch>
              )
            }
            <div className={classNames('flex-1 flex py-10 cursor-text px-6 min-w-0 sm:px-10', { 'flex-wrap': displayPopup || showAllSuburbs })} onClick={inputAreaClick}>
              {
                isMapArea
                  ? <SuburbItem more>Map area</SuburbItem>
                  : map(viewSuburbs, item => (
                    <SuburbItem data={item} key={item.id}></SuburbItem>
                  ))
              }
              {
                size(suburbs) > size(viewSuburbs) && (
                  <SuburbItem more onClick={showAllSuburbsHandle}>+{size(suburbs) - 1}</SuburbItem>
                )
              }
              {
                displayPopup && size(suburbs) > 10 && (
                  <SuburbItem more onClick={clearSuburbs}>Clear</SuburbItem>
                )
              }
              <input
                type='text'
                className='m-4 flex-1 h-26 text-base min-w-[18px] text-black'
                ref={inputRef}
                placeholder={hasChoice ? undefined : (placeholderText)}
                value={keyword}
                onChange={changeKeyword}
                readOnly={inputReadOnly}
                autoFocus={autoFocus}
                aria-label='Type an address, suburb, or city'
              />
            </div>
            {
              filterButton && (
                <button
                  type='button'
                  className={classNames(
                    'px-10 sm:px-20 relative flex items-center before:w-2 before:h-22 before:bg-neutral before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2',
                    filterButtonClassName
                  )}
                  onClick={filterHandle}
                >
                  <IconFilter className='fill-primary w-26 h-26 sm:w-30 sm:h-30'></IconFilter>
                </button>
              )
            }
          </div>
        </div>
        {
          showSearchButton && (
            <div className={classNames(
              'w-full sm:w-auto h-auto px-10 py-14 bg-white sm:border-y sm:py-10 absolute top-full left-0 sm:static',
              displayPopup ? 'rounded-b-sm sm:rounded-b-none sm:rounded-tr-sm' : 'rounded-b-sm sm:rounded-bl-none sm:rounded-r-sm'
            )}>
              <button type='button' className={classNames('block button-orange w-full h-48 sm:w-114 sm:h-full', { '': displayPopup })} onClick={searchHandle} aria-label='search'>
                <span className='text-base sm:text-lg'>Search</span>
                {/* <i className='icon icon-search !text-xl hidden sm:block'></i> */}
              </button>
            </div>
          )
        }
      </div>
      {children}
    </div>
  )
}

function TypeOption({
  typesConfig,
  type,
  setType,
  toggleTypeVisible
}) {

  return (
    <div className='absolute z-10 left-24 top-full -translate-y-12 bg-white rounded-sm w-134 py-3 shadow-[0_0_32px_0_rgba(0,0,0,0.2)]'>
      {
        map(typesConfig, item => (
          <div
            key={item.value}
            className='h-44 flex items-center justify-between px-14 text-base sm:text-lg sm:font-normal cursor-pointer border-t first:border-t-0'
            onClick={() => { toggleTypeVisible(false); setType(item) }}
          >
            {item.label}
            {
              isEqual(type, item) && (
                <i className='icon icon-correct font-bold text-secondary !text-lg'></i>
              )
            }
          </div>
        ))
      }
    </div>
  )
}
