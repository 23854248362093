'use client'

import useFetch from '@hooks/useFetch'
import { filter, find, map, size } from 'lodash-es'
import { useEffect, useMemo, useState } from 'react'
import { PopupGroup } from './index'
import { ITEM_TYPE, getSuggestedSuburbs, pertainType } from '../context'

export default function Suggested({
  region = true,
  district = true,
  showSuburb = true,
  priorityData
}) {

  const [suggestedSuburbs, setSuggestedSuburbs] = useState(null)
  useEffect(() => setSuggestedSuburbs(getSuggestedSuburbs(showSuburb)), [setSuggestedSuburbs, showSuburb])
  const [params, list] = useMemo(() => {
    const list = filter(suggestedSuburbs, item => {
      let res = true
      if (item.level === 'region') {
        res = region
      } else if (item.level === 'district') {
        res = district
      }
      return res
    })
    return [
      { locationJson: JSON.stringify(map(list, item => ({ id: item.id, lvl: item.level }))) },
      list
    ]
  }, [region, district, suggestedSuburbs])

  const { data } = useFetch(suggestedSuburbs === null ? null : { url: 'enum/locations-count', params })
  const parseData = useMemo(() => {
    return map(pertainType(list, ITEM_TYPE.suburb), item => {
      let d = find(data, item)
      if (d) item.houseCount = d.houseCount
      return item
    })
  }, [list, data])

  const viewData = size(priorityData) > 0 ? priorityData : parseData
  return size(viewData) > 0 && (
    <PopupGroup title='Suggested suburbs to add' data={viewData} suburb showLevel={false} className='pb-0 !border-t-0'></PopupGroup>
  )
}