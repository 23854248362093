import SelectLocation from '@component/selectLocation'
import useHomeSearchContext from '../context'
import { each, first } from 'lodash-es'

export default function Location({
  useRegion = true,
  useDistrict = true,
  useSuburb = true
}) {

  const { displaySelectLocation, hideSelectLocation, addSuburb, setSuburbs, oneSuburb, isSearchSuburb } = useHomeSearchContext()

  function onConfirm(suburbs) {
    localStorage.setItem('or-home-search-select-suburb', true)
    if (isSearchSuburb) {
      setSuburbs([first(suburbs)])
    } else {
      each(suburbs, item => {
        addSuburb(item)
      })
    }
    hideSelectLocation()
  }

  return (
    <SelectLocation show={displaySelectLocation} oneSuburb={isSearchSuburb || oneSuburb} useRegion={useRegion} useDistrict={useDistrict} useSuburb={useSuburb} onClose={hideSelectLocation} onConfirm={onConfirm}></SelectLocation>
  )
}