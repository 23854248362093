import { AGENT_TYPE_ID, HOUSE_TYPE_ID, LIVING_OPTIONS } from '@config'
import { agentSearchType, houseSearchType } from '@routerConfig'
import { assign } from 'lodash-es'

// agent type alias
export const AGENT_TYPE_ALIAS = {
  residential: 'ResidentialAgent',
  rural: 'RuralAgent',
  commercial: 'CommercialAgent',
  business: 'BusinessAgent',
  managment: 'PropertyManager',
  realEstate: 'RealEstate',
}
// agent search types
export const AGENT_SEARCH_TYPES = [
  {
    label: 'Residential agents',
    typeId: AGENT_TYPE_ID.residential,
    route: agentSearchType.residential,
    alias: AGENT_TYPE_ALIAS.residential
  },
  {
    label: 'Rural agents',
    typeId: AGENT_TYPE_ID.rural,
    route: agentSearchType.rural,
    alias: AGENT_TYPE_ALIAS.rural
  },
  {
    label: 'Commercial agents',
    typeId: AGENT_TYPE_ID.commercial,
    route: agentSearchType.commercial,
    alias: AGENT_TYPE_ALIAS.commercial
  },
  {
    label: 'Business agents',
    typeId: AGENT_TYPE_ID.business,
    route: agentSearchType.business,
    alias: AGENT_TYPE_ALIAS.business
  },
  {
    label: 'Property managers',
    typeId: null,
    route: agentSearchType.managment,
    alias: AGENT_TYPE_ALIAS.managment
  },
  {
    label: 'Real estate offices',
    typeId: null,
    route: agentSearchType.realEstate,
    alias: AGENT_TYPE_ALIAS.realEstate
  }
]
// get agent type
export function getAgentSearchType(idx, ops) {
  return assign(AGENT_SEARCH_TYPES[idx], ops)
}


// Residential alias
export const RESIDENTIAL_ALIAS = {
  residential: 'buy',
  estimate: 'valuation',
  rent: 'rent',
  sold: 'sold',
  suburb: 'suburb'
}
// Residential search types
export const RESIDENTIAL_SEARCH_TYPES = [
  {
    label: 'Houses for sale',
    typeId: HOUSE_TYPE_ID.residential,
    route: houseSearchType.residential,
    alias: RESIDENTIAL_ALIAS.residential
  },
  {
    label: 'Property estimate',
    typeId: null,
    route: null,
    alias: RESIDENTIAL_ALIAS.estimate,
    confirmText: 'Show property estimate(s)'
  },
  {
    label: 'Houses for rent',
    typeId: HOUSE_TYPE_ID.rent,
    route: houseSearchType.rent,
    alias: RESIDENTIAL_ALIAS.rent
  },
  {
    label: 'Sold property',
    typeId: HOUSE_TYPE_ID.sold,
    route: houseSearchType.sold,
    alias: RESIDENTIAL_ALIAS.sold
  },
  {
    label: 'Find a suburb',
    typeId: null,
    route: null,
    alias: RESIDENTIAL_ALIAS.suburb,
    confirmText: 'Show Suburb Profile'
  }
]
// get residential type
export function getResidentialSearchType(idx, ops) {
  return assign({}, RESIDENTIAL_SEARCH_TYPES[idx], ops)
}

// new homes alias
export const NEW_HOMES_ALIAS = {
  apartments: 'apartmentsAndTerraces',
  land: 'HouseAndLand',
  builders: 'builders',
}
// new homes search types
export const NEW_HOMES_SEARCH_TYPES = [
  {
    label: 'Apartments & terraces',
    typeId: HOUSE_TYPE_ID['new-homes'],
    params: { isProject: 1, isLandHome: 0 },
    route: houseSearchType.newHomes,
    alias: NEW_HOMES_ALIAS.apartments
  },
  {
    label: 'House & land',
    typeId: HOUSE_TYPE_ID['new-homes'],
    params: { isProject: 1, isLandHome: 1 },
    route: houseSearchType.newHomes,
    alias: NEW_HOMES_ALIAS.land
  },
  {
    label: 'Home builders',
    typeId: null,
    params: { limit: 1 },
    route: null,
    alias: NEW_HOMES_ALIAS.builders,
    url: 'builders',
    confirmTotalText: 'Show {totals} builders'
  }
]
// get new homes type
export function getNewHomeSearchType(idx, ops) {
  return assign(NEW_HOMES_SEARCH_TYPES[idx], ops)
}

// commercial alias
export const COMMERCIAL_ALIAS = {
  forSale: 'commercial for sale',
  forLease: 'commercial for lease',
}
// commercial search types
export const COMMERCIAL_SEARCH_TYPES = [
  {
    label: 'Commercial for Sale',
    typeId: HOUSE_TYPE_ID['commercial-property-for-sale'],
    route: houseSearchType.commercialForSale,
    alias: COMMERCIAL_ALIAS.forSale
  },
  {
    label: 'Commercial for Lease',
    typeId: HOUSE_TYPE_ID['commercial-property-for-lease'],
    route: houseSearchType.commercialForLease,
    alias: COMMERCIAL_ALIAS.forLease
  }
]
// get commercial type
export function getCommercialSearchType(idx, ops) {
  return assign(COMMERCIAL_SEARCH_TYPES[idx], ops)
}


// businesses alias
export const businesses_ALIAS = {
  forSale: 'businesses for sale'
}
// businesses search types
export const businesses_SEARCH_TYPES = [
  {
    label: 'Businesses for Sale',
    typeId: HOUSE_TYPE_ID['businesses-for-sale'],
    route: houseSearchType.business,
    alias: businesses_ALIAS.forSale
  }
]
// get businesses type
export function getBusinessesSearchType(idx, ops) {
  return assign(businesses_SEARCH_TYPES[idx], ops)
}

// rural alias
export const RURAL_ALIAS = {
  rural: 'rural',
}
// rural search types
export const RURAL_SEARCH_TYPES = [
  {
    label: 'Rural',
    typeId: HOUSE_TYPE_ID.rural,
    route: houseSearchType.rural,
    alias: RURAL_ALIAS.rural
  }
]
// get rural type
export function getRuralSearchType(idx, ops) {
  return assign(RURAL_SEARCH_TYPES[idx], ops)
}


// suburb alias
export const SUBURB_ALIAS = {
  suburb: 'suburb'
}
// suburb search types
export const SUBURB_SEARCH_TYPES = [
  {
    label: 'Find a suburb',
    typeId: null,
    route: null,
    alias: SUBURB_ALIAS.suburb,
    confirmText: 'Show Suburb Profile'
  }
]
// get suburb type
export function getSuburbSearchType(idx, ops) {
  return assign(SUBURB_SEARCH_TYPES[idx], ops)
}

// village alias
export const VILLAGE_ALIAS = {
  any : 'any',
  independentLiving: 'independent-living',
  servicedApartments: 'serviced-apartments',
  assistedLiving: 'assisted-living',
  specialistCareHomes: 'specialist-care-homes'
}
// village search types
export const VILLAGE_SEARCH_TYPES = [
  {
    label: 'Any',
    typeId: HOUSE_TYPE_ID.village,
    route: houseSearchType.retirementLiving,
    alias: VILLAGE_ALIAS.any
  },
  {
    label: 'Independent living',
    typeId: HOUSE_TYPE_ID.village,
    route: houseSearchType.retirementLiving,
    alias: VILLAGE_ALIAS.independentLiving,
    params: { livingOptions: LIVING_OPTIONS.independentLiving }
  },
  {
    label: 'Serviced apartments',
    typeId: HOUSE_TYPE_ID.village,
    route: houseSearchType.retirementLiving,
    alias: VILLAGE_ALIAS.servicedApartments,
    params: { livingOptions: LIVING_OPTIONS.servicedApartments }
  },
  {
    label: 'Assisted living',
    typeId: HOUSE_TYPE_ID.village,
    route: houseSearchType.retirementLiving,
    alias: VILLAGE_ALIAS.assistedLiving,
    params: { livingOptions: LIVING_OPTIONS.assistedLiving }
  },
  {
    label: 'Specialist care homes',
    typeId: HOUSE_TYPE_ID.village,
    route: houseSearchType.retirementLiving,
    alias: VILLAGE_ALIAS.specialistCareHomes,
    params: { livingOptions: LIVING_OPTIONS.specialistCareHomes }
  },
]
// get village type
export function getVillageSearchType(idx, ops) {
  return assign({}, VILLAGE_SEARCH_TYPES[idx], ops)
}

/**
 * 判断是否选中了find a suburb
 * @param {object} searchType 
 * @returns boolean
 */
export function isSuburbSearchType(searchType) {
  return searchType && searchType.alias === RESIDENTIAL_ALIAS.suburb
}

/**
 * 判断是否选中了sold
 * @param {object} searchType 
 * @returns boolean
 */
export function isSoldSearchType(searchType) {
  return searchType && searchType.alias === RESIDENTIAL_ALIAS.sold
}